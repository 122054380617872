import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import './Modal.scss';

const ModalOverlay = (props) => {
  let modalClassName = 'modal';
  let modalHeaderClassName = 'modal__header';
  let modalContentClassName = 'modal__content';
  let modalFooterClassName = 'modal__footer';

  if (props.className) {
    modalClassName += ` ${props.className}`;
  }
  if (props.headerClass) {
    modalHeaderClassName += ` ${props.headerClass}`;
  }
  if (props.contentClass) {
    modalContentClassName += ` ${props.contentClass}`;
  }
  if (props.footerClass) {
    modalFooterClassName += ` ${props.footerClass}`;
  }

  const content = (
    <div className={modalClassName} style={props.style}>
      <header className={modalHeaderClassName}>
        {props.header}
      </header>
      <form
        onSubmit={
          props.onSubmit ? props.onSubmit : event => event.preventDefault()
        }
      >
        <div className={modalContentClassName}>
          {props.children}
        </div>
        <footer className={modalFooterClassName}>
          {props.footer}
        </footer>
      </form>
    </div>
  );
  // The portal below is depreciated and gives errors
  return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const ModalBackdrop = (props) => {
  const content = (
    <div className='modal__backdrop' onClick={props.onCancel}></div>
  );
  // The portal below is depreciated and gives errors
  return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

/**
 * Component for rendering a modal.
 * 
 * Props: className, style, header, headerClass, onSubmit, footer, footerClass, show (display modal), onCancel (close modal)
 * 
 * Notes: Takes children instead of content prop
 */
const Modal = (props) => {

  useEffect(() => {
    const closeModalOnEscapeKey = (e) => {
      if (e.key === 'Escape') {
        props.onCancel();
      }
    };
    if (props.show === true) {
      window.addEventListener('keydown', closeModalOnEscapeKey);
    }
  }, [props]);

  return (
    <>
      {props.show && <ModalBackdrop {...props} onCancel={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="modal"
      >
        <ModalOverlay {...props} />
      </CSSTransition>
    </>
  );
};

export default Modal;