import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import "./OffCanvasPanel.scss";

const OffCanvasPanel = (props) => {
  const [domReady, setDomReady] = useState(false)

  useEffect(() => {
    setDomReady(true)
  }, [])

  let offCanvasPanelClassname = 'off-canvas-panel';

  if (props.className) {
    offCanvasPanelClassname += ` ${props.className}`;
  }
  // Do not combine the following if statements,
  // separating them allows for null so the panel doesn't animate on load.
  if (props.visible === true) {
    offCanvasPanelClassname += ` off-canvas-panel--visible`;
  }
  if (props.visible === false) {
    offCanvasPanelClassname += ` off-canvas-panel--hidden`;
  }
  if (props.slideInDirection) {
    offCanvasPanelClassname += ` off-canvas-panel--slide-in-${props.slideInDirection}`;
  }

  const closeOffCanvasPanelHandler = () => {
    props.onCloseButtonClick();
  }

  const content = (
    <div className={offCanvasPanelClassname}>
      <div className='off-canvas-panel__close-button'>
        <IconButton onClick={closeOffCanvasPanelHandler}>
          <CloseIcon />
        </IconButton>
      </div>
      {props.children}
    </div>
  );

  return domReady ? ReactDOM.createPortal(content, document.getElementById('off-canvas-panel-hook')) : null;
  
};

export default OffCanvasPanel;