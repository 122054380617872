import { useState, useEffect, useCallback } from 'react';

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
	const [userId, setUserId] = useState(false);
	const [tokenExpirationDate, setTokenExpirationDate] = useState();

	// Set token on login
	const login = useCallback((id, token, expirationDate) => {
    setToken(token);
		setUserId(id);
		const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 14); // 2 weeks
		setTokenExpirationDate(tokenExpirationDate);
		localStorage.setItem('userData', JSON.stringify({
			userId: id,
			token: token,
			expiration: tokenExpirationDate.toISOString()
		}));
  }, []);

	// Remove token on logout
  const logout = useCallback(() => {
    setToken(null);
		setTokenExpirationDate(null);
		setUserId(null);
		localStorage.removeItem('userData');
  }, []);

	// Set the token timer
	useEffect(() => {
		if (token && tokenExpirationDate) {
			const remainingTime = tokenExpirationDate.getTime() - new Date();
			logoutTimer = setTimeout(logout, remainingTime);
		} else {
			clearTimeout(logoutTimer);
		}
	}, [token, logout, tokenExpirationDate]);

	// Show alert if session is within 1 hour of expiration
  useEffect(() => {
    if (tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date();
      const withinHour = remainingTime <= 1000 * 60 * 60;
      if (withinHour) {
        alert('Your session will expire in less than 1 hour. Please refresh your session.');
      }
    }
  }, [tokenExpirationDate]);

	// When token expires
	useEffect(() => {
		const storedData = JSON.parse(localStorage.getItem('userData'));
		if (storedData && storedData.token && new Date(storedData.expiration) > new Date()) {
			login(storedData.userId, storedData.token, new Date(storedData.expiration));
		}
	}, [login]);

  return { token, login, logout, userId };
};