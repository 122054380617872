import React, { useState, Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from 'context/auth-context';
import { useHttpClient } from 'hooks/http-hook';
import { useAuth } from 'hooks/auth-hook';
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import Taskbar from "components/Taskbar/Taskbar";
import Snackbar from '@mui/material/Snackbar';
import AppRoutes from './AppRoutes';
import "App.scss";

const App = () => {
	const { token, login, logout, userId } = useAuth();
	const { sendRequest } = useHttpClient();
	const { state } = useLocation();
	let location = useLocation();
  const [jesseAccountPublicViewMode, setJesseAccountPublicViewMode] = useState(localStorage.getItem('jesseAccountPublicViewMode') || 'public');
	const [taskbarClassName, setTaskbarClassName] = useState('');
	const [userCreatedPages, setUserCreatedPages] = useState([]);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [openSnackbar, setOpenSnackbar] = useState(false);

  // Update localStorage when the privacy state changes
  useEffect(() => {
    localStorage.setItem('jesseAccountPublicViewMode', jesseAccountPublicViewMode);
  }, [jesseAccountPublicViewMode]);

	// Toggle privacy mode (jesse account only).
	const toggleJesseAccountPublicViewHandler = () => {
    setJesseAccountPublicViewMode(prevJesseAccountPublicViewMode => prevJesseAccountPublicViewMode === 'public' ? 'private' : 'public');
  };

	// Modify appearance of components depending on page/location.
	useEffect(() => {
		if (location.pathname === '/auth' || ( location.pathname === '/' && !token) ) {
			setTaskbarClassName('taskbar--hidden');
		} else if (location.pathname === '/' || location.pathname.includes('flash-cards-game') || location.pathname.includes('wallpapers/preview')) {
			setTaskbarClassName('taskbar--black-transparent');
		} else {
			setTaskbarClassName('')
		}
	}, [location, token]);

	// Show snackbar message from another page.
	useEffect(() => {
		if (state?.passSnackbar) {
			setSnackbarMessage(state.snackbarMessage);
			setOpenSnackbar(true);
		}
	}, [state]);

	// Get user created pages to load into mainNav.
	useEffect(() => {
		const getPages = async () => {
			try {
				const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/pages/user/${userId}`);
				setUserCreatedPages(responseData.pages);
			} catch (err) {}
		}
		if (userId) {
			getPages();
		}
	}, [userId, sendRequest]);

	// Close snackbar.
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

	return (
		<AuthContext.Provider value={{ isLoggedIn: !!token, token: token, userId: userId, login: login, logout: logout }}>
			<div id='off-canvas-panel-hook'></div>
			<div id='modal-hook'></div>
			<div className='app'>
				<Taskbar
					className={taskbarClassName}
					jesseAccountPublicViewMode={jesseAccountPublicViewMode}
					onToggleJesseAccountPublicView={toggleJesseAccountPublicViewHandler}
					userCreatedPages={userCreatedPages}
				/>
				<div className='app__content-area'>
					<Suspense fallback={
						<LoadingAnimation asOverlay />
					}>
						<AppRoutes userCreatedPages={userCreatedPages} />
					</Suspense>
				</div>
				<Snackbar
					open={openSnackbar}
					autoHideDuration={2500}
					onClose={handleCloseSnackbar}
					message={snackbarMessage}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				/>
			</div>
		</AuthContext.Provider>
	);
};

export default App;