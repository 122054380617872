import React from 'react';
import R2Icon from '../R2Icon/R2Icon';
import './LoadingAnimation.scss';

const LoadingAnimation = props => {
  let loadingAnimationClassname = 'loading-animation';
  if (props.asOverlay) {
    loadingAnimationClassname += ` loading-animation--overlay`
  }

  return (
    <div className={loadingAnimationClassname}>
      <R2Icon />
    </div>
  );
};

export default LoadingAnimation;
