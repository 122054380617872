import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from 'context/auth-context';
import OffCanvasPanel from "../OffCanvasPanel/OffCanvasPanel";
import CreateNav from "../CreateNav/CreateNav";
import Input from '../FormElements/Input/Input';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from "@mui/material";
import "./SearchAndCreateBar.scss";

/**
 * Component for rendering the search and create bar
 * 
 * Props: className, onUse, jesseAccountPublicViewMode
 */
const SearchAndCreateBar = (props) => {
	const auth = useContext(AuthContext);
	const [input, setInput] = useState("");
	const [offCanvasPanelVisible, setOffCanvasPanelVisible] = useState(null);
	const navigate = useNavigate();

	let searchAndCreateBarClassName = 'search-and-create-bar';
	if (props.className) {
		searchAndCreateBarClassName += ` ${props.className}`;
	}

	const searchSubmitHandler = (e) => {
		e.preventDefault();
		setInput('');
		props.onUse();
		navigate(`/search/${auth.userId}/${input.toLowerCase()}`);
	}

	const searchInputChangeHandler = (value) => {
		setInput(value);
	};

	const toggleOffCanvasPanelVisibility= () => {
		props.onUse();
		setOffCanvasPanelVisible(!offCanvasPanelVisible);
	}

	return (
		<div className={searchAndCreateBarClassName}>
			<form className="search-and-create-bar__search-form" autoComplete="off" onSubmit={(e) => searchSubmitHandler(e)}>
				<Input
					inputClassName="search-and-create-bar__search-input"
					type="text"
					onChange={(e) => searchInputChangeHandler(e.target.value)}
					value={input}
				/>
				<div className="search-and-create-bar__search-icon">
					<SearchIcon />
				</div>
			</form>

			<div className="search-and-create-bar__create-button">
				<IconButton onClick={toggleOffCanvasPanelVisibility}>
					<AddIcon />
				</IconButton>
			</div>

			<OffCanvasPanel
				visible={offCanvasPanelVisible}
				onCloseButtonClick={toggleOffCanvasPanelVisibility}
				slideInDirection='top'
				closeButtonPosition=''
			>
				<CreateNav 
					onClickCreateIcon={toggleOffCanvasPanelVisibility}
					jesseAccountPublicViewMode={props.jesseAccountPublicViewMode}
				/>
			</OffCanvasPanel>

		</div>
	);
};

export default SearchAndCreateBar;