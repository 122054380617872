import { useState, useEffect } from 'react';

/**
 * Checks if the date has changed every minute. If so, returns the new date.
 */
export const useCurrentDate = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      // Check if the day has changed
      if (
        now.getFullYear() !== currentDate.getFullYear() ||
        now.getMonth() !== currentDate.getMonth() ||
        now.getDate() !== currentDate.getDate()
      ) {
        setCurrentDate(now);
      }
    }, 1000 * 60); // Check every minute

    return () => clearInterval(timer);
  }, [currentDate]);

  return currentDate;
};
