import { React, useState, useContext } from "react";
import { AuthContext } from 'context/auth-context';
import MainNav from "../MainNav/MainNav";
import OffCanvasPanel from "../OffCanvasPanel/OffCanvasPanel";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import SearchAndCreateBar from "../SearchAndCreateBar/SearchAndCreateBar";
import CircleIcon from '@mui/icons-material/Circle';
import "./Taskbar.scss";

/**
 * Component for rendering the taskbar.
 * 
 * props: className, jesseAccountPublicViewMode, userCreatedPages (to pass through to MainNav)
 */
const Taskbar = (props) => {
	const auth = useContext(AuthContext);
	const [mainNavVisible, setMainNavVisible] = useState(null);
	const [mainNavClassName, setMainNavClassName] = useState('mainnav--hide-all-subnavs');

	const toggleMainNavVisibility = () => {
		setMainNavVisible(!mainNavVisible);
		setMainNavClassName('mainnav--hide-all-subnavs');
	}

	const closeMainNav = () => {
		setMainNavClassName('mainnav--hide-all-subnavs');
		setMainNavVisible(false);
	}

	let taskbarClassName = 'taskbar';
	if (props.className) {
		taskbarClassName += ` ${props.className}`;
	}

	let taskbarPrivacyIndicatorClassName = 'taskbar__icon taskbar__privacy-indicator';
	if (props.jesseAccountPublicViewMode === 'public') {
		taskbarPrivacyIndicatorClassName += ' taskbar__privacy-indicator--public';
	} else if (props.jesseAccountPublicViewMode === 'private') {
		taskbarPrivacyIndicatorClassName += ' taskbar__privacy-indicator--private';
	}

	return (
		<div className={taskbarClassName}>
				<div className="taskbar__icon-set-secondary">
					{auth.userId === `${process.env.REACT_APP_JESSE_ID}` &&
						<IconButton onClick={() => {props.onToggleJesseAccountPublicView()}}>
							<CircleIcon className={taskbarPrivacyIndicatorClassName} />
						</IconButton>
					}
				</div>
			<div className="taskbar__icon-set-primary">
				<div id='taskbar-page-actions-hook'></div>
				<IconButton onClick={toggleMainNavVisibility}>
					<MenuIcon className="taskbar__icon" />
				</IconButton>
				<OffCanvasPanel
					visible={mainNavVisible}
					onCloseButtonClick={toggleMainNavVisibility}
					slideInDirection='left'
					closeButtonPosition=''
				>
					{auth.userId === `${process.env.REACT_APP_JESSE_ID}` &&
						<div className="taskbar__mainnav-privacy-indicator">
							<IconButton onClick={() => {props.onToggleJesseAccountPublicView()}}>
								<CircleIcon className={taskbarPrivacyIndicatorClassName} />
							</IconButton>
						</div>
					}
					<MainNav
						mainNavClassName={mainNavClassName}
						jesseAccountPublicViewMode={props.jesseAccountPublicViewMode}
						onCloseMainNav={toggleMainNavVisibility}
						userCreatedPages={props.userCreatedPages}
					/>
					<SearchAndCreateBar
						onUse={closeMainNav}
						jesseAccountPublicViewMode={props.jesseAccountPublicViewMode}
					/>
				</OffCanvasPanel>
			</div>
		</div>
	);
};

export default Taskbar;
