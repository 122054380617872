import "./R2Icon.scss";

const R2Icon = () => {
	
	return (
    <div className="r2-icon">
      <div className='r2-icon__bar'></div>
      <div className='r2-icon__bar r2-icon__bar--bottom'></div>
    </div>
	);
};

export default R2Icon;
