import { Link } from 'react-router-dom';
import './Button.scss';

/**
 * Component for rendering a button.
 * By default, the component renders an anchor tag.
 * To use a button tag, use the prop "element='button'".
 * 
 * Props: classname, disabled, element (button), target, text, type (submit / button), url, variant (text / outlined)
 */
const Button = (props) => {

  let buttonClassName = 'button';

  if (props.className) {
    buttonClassName += ` ${props.className}`;
  } 

  if (props.variant === 'text') {
    buttonClassName += ' button--text';
  } else if (props.variant === 'outlined') {
    buttonClassName += ' button--outlined';
  }

  if (props.element === 'button') {
    return (
      <button
        className={buttonClassName}
        disabled={props.disabled}
        type={props.type}
        onClick={props.onClick}
      >
        {props.text}
      </button>
    );
  }

  return (
    <Link
      className={buttonClassName}
      target={props.target}
      to={props.url}
      variant={props.variant}
      onClick={props.onClick}
    >
      {props.text}
    </Link>
  );
};

export default Button;