import { useState, useEffect, useContext} from "react";
import { useHttpClient } from 'hooks/http-hook';
import { AuthContext } from 'context/auth-context';
import SearchAndCreateBar from "components/SearchAndCreateBar/SearchAndCreateBar";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import './Homepage.scss';

const Homepage = (props) => {
	const auth = useContext(AuthContext);
	const { sendRequest, isLoading } = useHttpClient();
	const [offCanvasPanelVisible, setOffCanvasPanelVisible] = useState(null);
	const [randomWallpaper, setRandomWallpaper] = useState(null);

	// Get User Wallpapers
	useEffect(() => {
		const fetchWallpapers = async () => {
			let tempWallpapers = [];
			try {
				const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/wallpapers/user/${auth.userId}`);
				const allWallpapers = responseData.wallpapers;
				const publicWallpapers = responseData.wallpapers.filter(x => x.public === true);

				if (props.jesseAccountPublicViewMode === 'private') {
					for (let wallpaper of allWallpapers) {
						tempWallpapers.push(wallpaper);
					}
				} else {
					for (let wallpaper of publicWallpapers) {
						tempWallpapers.push(wallpaper);
					}
				}
				setRandomWallpaper(tempWallpapers[Math.floor(Math.random()*tempWallpapers.length)]);
			} catch (err) { }
		}
		fetchWallpapers();
	}, [props.jesseAccountPublicViewMode, sendRequest, auth.userId]);

	// Toggles the Create Nav (with icons) Panel
	const toggleOffCanvasPanelVisibility= () => {
		setOffCanvasPanelVisible(!offCanvasPanelVisible);
	}

	if (isLoading) {
    return (
      <LoadingAnimation asOverlay />
    );
  }

	if (randomWallpaper === null) {
    return (
      <div className="homepage">
				<SearchAndCreateBar
					className="search-and-create-bar--black-transparent"
					onUse={toggleOffCanvasPanelVisibility}
				/>
			</div>
    );
  }

	let wallPaperStyles = {};

	if (randomWallpaper?.css?.length > 0) {
		const customStyle = JSON.parse(randomWallpaper?.css);
		wallPaperStyles = customStyle;
	}

	if (randomWallpaper?.image !== null) {
		wallPaperStyles = {...wallPaperStyles, backgroundImage: `url(${randomWallpaper?.image?.imageUrl})`};
	}

	return (
		<div className="homepage" style={wallPaperStyles}>
			<SearchAndCreateBar
				className="search-and-create-bar--black-transparent"
				onUse={toggleOffCanvasPanelVisibility}
				jesseAccountPublicViewMode={props.jesseAccountPublicViewMode}
			/>
		</div>
	);
};

export default Homepage;