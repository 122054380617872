import CreateIcon from '../CreateIcon/CreateIcon';
import './CreateNav.scss';

/**
 * Component for rendering the nav list of create links.
 * 
 * Props: onClickCreateIcon, jesseAccountPublicViewMode
 */
const CreateNav = (props) => {

  const onClickCreateIconHandler = () => {
    props.onClickCreateIcon();
  }

  return (
    <div className='create-nav'>
      {props.jesseAccountPublicViewMode === 'private' &&
        <CreateIcon
          text='Day'
          to='/days/create'
          onClick={onClickCreateIconHandler}
        />
      }
      <CreateIcon
        text='Flash Card'
        to='/flash-cards/create'
        onClick={onClickCreateIconHandler}
      />
      <CreateIcon
        text='Note'
        to='/notes/create'
        onClick={onClickCreateIconHandler}
      />
      <CreateIcon
        text='Page'
        to='/pages/create'
        onClick={onClickCreateIconHandler}
      />
      {props.jesseAccountPublicViewMode === 'private' &&
        <CreateIcon
          text='Phrase'
          to='/phrases/create'
          onClick={onClickCreateIconHandler}
        />
      }
      <CreateIcon
        text='Task'
        to='/tasks/create'
        onClick={onClickCreateIconHandler}
      />
      <CreateIcon
        text='Wallpaper'
        to='/wallpapers/create'
        onClick={onClickCreateIconHandler}
      />
    </div>
  )
};

export default CreateNav;