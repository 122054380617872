import React from 'react';
import Modal from '../Modal/Modal';

/**
 * Component for an error modal
 *  
 * Props: error (boolean - shows the error), onClear
 */
const ErrorModal = (props) => {
  return (
    <Modal
      onCancel={props.onClear}
      header="Error"
      show={!!props.error}
      footer={
        <span className='action' onClick={props.onClear}>Close</span>
      }
    >
      <p>{props.error}</p>
    </Modal>
  );
};

export default ErrorModal;