import { NavLink } from "react-router-dom";
import './CreateIcon.scss';

/**
 * Component for rendering create panel icon.
 * 
 * Props: text, to (navigate to)
 */
const CreateIcon = (props) => {
  const letter = props.text.charAt(0);

  const onClickCreateIconHandler = () => {
    props.onClick();
  }

  return (
    <NavLink
      className='create-icon'
      to={props.to}
      onClick={onClickCreateIconHandler}
    >
      <div className='create-icon__block'>
        <div className='create-icon__letter'>
          <span>{letter}</span>
        </div>
      </div>
      <div className='create-icon__text'>
        {props.text}
      </div>
    </NavLink>
  )
};

export default CreateIcon;