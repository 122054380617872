import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './Tooltip.scss';

/**
 * Component for rendering a tooltip.
 * 
 * Props: className, content, icon, label
 */
const Tooltip = (props) => {
  const [tooltipClassName, setTooltipClassName] = useState(`tooltip${props.className ? ` ${props.className}` : ``}`);
  const [xPosition, setXPosition] = useState('');
  const [yPosition, setYPosition] = useState('');

  let className = tooltipClassName;

  // Determine expand direction
  const determineExpandDirection = () => {
    if(yPosition < (window.innerHeight / 2) && !className.includes(' tooltip--expand-down')) {
      className += ' tooltip--expand-down';
      setTooltipClassName(className);
    }
    if(xPosition < (window.innerWidth / 2) && !className.includes(' tooltip--expand-right')) {
      className += ' tooltip--expand-right';
      setTooltipClassName(className);
    }
  }

  // Toggle visibility
  const toggleTooltipHandler = () => {
    determineExpandDirection();
    if (className.includes(' tooltip--active')) {
      className = className.replace(' tooltip--active', '');
      setTooltipClassName(className);
    } else {
      className += ' tooltip--active';
      setTooltipClassName(className);
    }
  }

  // Close tooltip
  const closeTooltipHandler = () => {
    className = className.replace(' tooltip--active', '');
    setTooltipClassName(className);
  }

  // Close tooltip by clicking outside of it
  window.addEventListener('click', (e) => {
    if (!e.target.matches('.tooltip, .tooltip *')) {
      closeTooltipHandler();
    }
  })

  return (
    <div
      className={tooltipClassName} 
      ref={tooltipRef => {
        if (!tooltipRef) return;
        const x = tooltipRef.getBoundingClientRect().x;
        const y = tooltipRef.getBoundingClientRect().y;
        setXPosition(x);
        setYPosition(y);
      }}
    >
      <div className='tooltip__icon' onClick={toggleTooltipHandler}>{props.icon ? props.icon : 'i'}</div>
      {props.label && <div className='tooltip__label' onClick={toggleTooltipHandler}>{props.label}</div>}
      <div className='tooltip__content'>
        <div className='tooltip__scrollable-container'>
          {props.content}
        </div>
        <div className='tooltip__close'>
          <CloseIcon onClick={closeTooltipHandler}/>
        </div>
      </div>
    </div>
  )
};

export default Tooltip;